import * as React from "react"
import Layout from "../components/layout"
import Background from "../components/Background"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
 

  <Layout>
  <Background/>
    <div className="fourohfourWrapper">
      <h1>404: <br/>Welcome to the void</h1>
      <Link to="/">Go back to the design stuff.</Link>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
